.popoverContent {
  width: 160px;
  z-index: 999;
  outline: none;
  animation-duration: 400ms;
  will-change: transform, opacity;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  max-height: 60vh;
}
.popoverContent .popoverContentWrapper {
  overflow-y: auto;
  max-height: 60vh;
  border-radius: 4px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  box-shadow: var(--box-shadow-main-content);
}
.popoverContent .popoverContentWrapper::-webkit-scrollbar {
  display: none;
}
.popoverContent .popoverArrow {
  filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.08));
  fill: white;
}
.popoverContent[data-state=open][data-side=top] {
  animation-name: slideDownAndFade;
}
.popoverContent[data-state=open][data-side=right] {
  animation-name: slideLeftAndFade;
}
.popoverContent[data-state=open][data-side=bottom] {
  animation-name: slideUpAndFade;
}
.popoverContent[data-state=open][data-side=left] {
  animation-name: slideRightAndFade;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}