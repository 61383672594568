:root {
  --black-transparent: #000000bf;
  --tooltip-move: 10px;
  --tooltip-height: 8px;
  /* Calculate negative position and mulplied with 2 */
  --tooltip-shift: calc(-2 * var(--tooltip-height));
}

.harsh-coach-mark {
  border: 1px solid var(--black-transparent);
  box-sizing: border-box;
  position: fixed;
  z-index: 9999998;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-shadow: 0px 0px 0px 2000px var(--black-transparent);
  border-radius: var(--tooltip-move);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.hcm-tooltip-base {
  box-sizing: content-box;
  position: fixed;
  z-index: 9999999;
  background-color: #ffffffdb;
  padding: var(--tooltip-move);
  border-radius: 4px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.hcm-tooltip-base-left::before,
.hcm-tooltip-base-bottom::before,
.hcm-tooltip-base-right::before,
.hcm-tooltip-base-top::before {
  content: "";
  border-top-width: var(--tooltip-height);
  border-left-width: var(--tooltip-height);
  border-right-width: var(--tooltip-height);
  border-bottom-width: var(--tooltip-height);
  border-style: solid;
  background: transparent;
  border-color: #ffffffdb;
  position: absolute;
}

.hcm-tooltip-base-left::before {
  top: var(--tooltip-move);
  right: var(--tooltip-shift);
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-right-color: transparent;
}

.hcm-tooltip-base-bottom::before {
  top: var(--tooltip-shift);
  left: var(--tooltip-move);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}

.hcm-tooltip-base-top::before {
  bottom: var(--tooltip-shift);
  left: var(--tooltip-move);
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}

.hcm-tooltip-base-right::before {
  left: var(--tooltip-shift);
  top: var(--tooltip-move);
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
