.sideNavContent {
  width: 200px;
  height: 100vh;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  background-color: var(--up-black-5);
  transition: width 300ms ease-in-out;
  box-shadow: inset -6px 0 6px -6px rgba(0, 0, 0, 0.08);
}
.sideNavContent .sideNavHeader {
  padding: 32px 16px;
}
.sideNavContent .sideNavFooter {
  border-top: 2px solid var(--up-black-10);
}
.sideNavContent .sideNavBody {
  height: 100%;
  overflow-y: auto;
  padding-bottom: 50px;
  /* prevent scroll-chaining to parent elements */
  overscroll-behavior: contain;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.sideNavContent .sideNavBody::-webkit-scrollbar {
  display: none;
}
.sideNavContent .sideNavBody .sideNavItemHeader {
  display: flex;
  cursor: pointer;
  align-items: center;
  height: fit-content;
  gap: 12px;
  margin: 6px 0;
  padding: 6px 12px;
  border-radius: 2px;
  border-left: 2px solid transparent;
}
.sideNavContent .sideNavBody .sideNavItemHeader .sideNavItemIcon {
  height: 20px;
  width: 20px;
  flex-shrink: 0;
  color: var(--up-black-40);
}
.sideNavContent .sideNavBody .sideNavItemHeader .lockIcon {
  height: 15px;
  width: 15px;
  color: var(--up-black-40);
}
.sideNavContent .sideNavBody .sideNavItemHeader .sideNavItemLabel {
  color: var(--up-black-60);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
}
.sideNavContent .sideNavBody .sideNavItemHeader .sideNavItemChevron {
  height: 16px;
  width: 16px;
  color: var(--up-black-40);
  transition: transform 300ms ease-in-out;
  flex-shrink: 0;
}
.sideNavContent .sideNavBody .sideNavItemHeader:hover {
  background: var(--up-black-10);
}
.sideNavContent .sideNavBody .sideNavItemHeader:hover .sideNavItemIcon {
  color: var(--up-black-60);
}
.sideNavContent .sideNavBody .sideNavItemHeader:hover .sideNavItemChevron {
  color: var(--up-black-60);
}
.sideNavContent .sideNavBody .sideNavAccordian {
  position: relative;
}
.sideNavContent .sideNavBody .sideNavItemOpen {
  background: var(--up-black-10);
}
.sideNavContent .sideNavBody .sideNavItemOpen .sideNavItemIcon {
  color: var(--up-black-60);
}
.sideNavContent .sideNavBody .sideNavItemOpen .sideNavItemChevron {
  color: var(--up-black-60);
}
.sideNavContent .sideNavBody .sideNavItemHeaderActive {
  color: var(--up-blue-40);
  background: var(--up-blue-7);
}
.sideNavContent .sideNavBody .sideNavItemHeaderActive::before {
  content: "";
  position: absolute;
  left: -1px;
  top: 9px;
  width: 5px;
  height: 28px;
  background: var(--up-blue-40);
  border-radius: 4px;
}
.sideNavContent .sideNavBody .sideNavItemHeaderActive.sideNavItemHeaderCollapsed::before {
  background: transparent;
}
.sideNavContent .sideNavBody .sideNavItemHeaderActive .sideNavItemLabel {
  color: var(--up-black-80);
  font-weight: 600;
}
.sideNavContent .sideNavBody .sideNavItemHeaderActive .sideNavItemIcon {
  color: var(--up-blue-40);
}
.sideNavContent .sideNavBody .sideNavItemHeaderActive .lockIcon {
  color: var(--up-blue-40);
}
.sideNavContent .sideNavBody .sideNavItemHeaderActive .sideNavItemChevron {
  color: var(--up-black-60);
}
.sideNavContent .sideNavBody .sideNavItemHeaderActive:hover {
  background: var(--up-blue-7);
}
.sideNavContent .sideNavBody .sideNavItemHeaderActive:hover .sideNavItemIcon {
  color: var(--up-blue-40);
}
.sideNavContent .sideNavBody .sideNavItemHeaderActiveWithChildren {
  color: unset;
  background: var(--up-black-10);
  border-left-color: transparent;
}
.sideNavContent .sideNavBody .sideNavItemHeaderActiveWithChildren.sideNavItemHeaderActive {
  background: var(--up-blue-7);
}
.sideNavContent .sideNavBody .sideNavItemHeaderActiveWithChildren .sideNavItemLabel {
  font-weight: 600;
}
.sideNavContent .sideNavBody .sideNavItemHeaderActiveWithChildren.sideNavItemOpen {
  background: var(--up-blue-7);
}
.sideNavContent .sideNavBody .sideNavItemContent {
  overflow: hidden;
  padding-left: 45px;
}
.sideNavContent .sideNavBody .sideNavItemContent .sideNavContentWrapper {
  cursor: pointer;
  border-radius: 2px;
  margin-bottom: 4px;
  height: fit-content;
  padding: 4px 12px;
  border-left: 2px solid transparent;
}
.sideNavContent .sideNavBody .sideNavItemContent .sideNavContentWrapper .sideNavItemChildLabel {
  width: 100%;
  color: var(--up-black-60);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.sideNavContent .sideNavBody .sideNavItemContent .sideNavContentWrapper .lockIcon {
  height: 15px;
  width: 15px;
  color: var(--up-black-40);
}
.sideNavContent .sideNavBody .sideNavItemContent .sideNavContentWrapper:hover {
  background: var(--up-black-10);
}
.sideNavContent .sideNavBody .sideNavItemContent .sideNavItemChildActive {
  width: 100%;
  background: var(--up-black-10);
  border-left-color: var(--up-blue-40);
}
.sideNavContent .sideNavBody .sideNavItemContent .sideNavItemChildActive .sideNavItemChildLabel {
  font-weight: 600;
  color: var(--up-blue-40);
}
.sideNavContent .sideNavBody .sideNavItemContent .sideNavItemChildActive .lockIcon {
  color: var(--up-blue-40);
}
.sideNavContent .sideNavBody .sideNavItemHeaderDisabled,
.sideNavContent .sideNavBody .sideNavItemChildDisabled {
  opacity: 0.6;
}
.sideNavContent .sideNavBody .sideNavAccordian[data-state=closed] .sideNavItemChevron {
  transform: rotate(0deg);
}
.sideNavContent .sideNavBody .sideNavAccordian[data-state=open] .sideNavItemChevron {
  transform: rotate(180deg);
}

.sideNavCollapsed {
  width: 60px;
}
.sideNavCollapsed .sideNavBody {
  padding: 0 12px 50px 8px;
}
.sideNavCollapsed .sideNavHeader {
  padding: 20px 12px 20px 8px;
}
.sideNavCollapsed .sideNavBody .sideNavItemHeader {
  padding: 0;
  height: 32px;
  margin: 0 0 6px 0;
  justify-content: center;
}
.sideNavCollapsed .sideNavBody .sideNavItemHeader span {
  padding: 0;
  width: 100%;
  height: 100%;
}
.sideNavCollapsed .sideNavBody .sideNavItemHeader .collapsedSideNavItemIcon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sideNavItemContentCollapsed {
  height: 100%;
  padding-bottom: 4px;
  background: var(--up-black-0);
}
.sideNavItemContentCollapsed .sideNavSubmenuItemLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  color: var(--up-black-80);
  font-weight: 600;
  padding: 12px;
  border-bottom: 1px solid var(--up-black-10);
}
.sideNavItemContentCollapsed .sideNavSubmenuItemLabel .lockIcon {
  height: 15px;
  width: 15px;
  color: var(--up-black-40);
}
.sideNavItemContentCollapsed.sideNavItemContentWithoutChildren {
  padding-bottom: 0;
}
.sideNavItemContentCollapsed.sideNavItemContentWithoutChildren .sideNavSubmenuItemLabel {
  border-bottom: none;
  padding-right: 12px;
}
.sideNavItemContentCollapsed .sideNavContentWrapper {
  width: 100%;
  padding: 4px;
  padding-bottom: 0;
  cursor: pointer;
  height: fit-content;
}
.sideNavItemContentCollapsed .sideNavContentWrapper .sideNavItemChildLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  width: 100%;
  color: var(--up-black-80);
  padding: 4px 12px;
  border-radius: 2px;
  border-left: 2px solid transparent;
}
.sideNavItemContentCollapsed .sideNavContentWrapper .sideNavItemChildLabel .lockIcon {
  height: 15px;
  width: 15px;
  color: var(--up-black-40);
}
.sideNavItemContentCollapsed .sideNavContentWrapper .sideNavItemChildLabel:hover {
  background: var(--up-black-5);
}
.sideNavItemContentCollapsed .sideNavItemChildActive .sideNavItemChildLabel {
  font-weight: 600;
  color: var(--up-blue-40);
  background: var(--up-black-5);
  border-left-color: var(--up-blue-40);
}
.sideNavItemContentCollapsed .sideNavItemChildActive .sideNavItemChildLabel .lockIcon {
  color: var(--up-blue-40);
}