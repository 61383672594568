.OnboardingNextUp {
  position: relative;
  border-radius: 8px;
  padding: var(--padding-24);
}
.OnboardingNextUp .StepperBackground {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  z-index: 10;
  background: linear-gradient(93.06deg, rgba(56, 84, 229, 0.042) -2.47%, rgba(56, 84, 229, 0.1) 110.1%);
}
.OnboardingNextUp .OnboardingComplete {
  display: grid;
  place-items: center;
}
.OnboardingNextUp .OnboardingComplete .OnboardingCompleteIcon {
  width: 84px;
  height: 84px;
  margin-bottom: 12px;
}
.OnboardingNextUp .OnboardingNextUpHeadingText {
  font-weight: 600;
  margin-bottom: 16px;
}
.OnboardingNextUp .NextUpCard {
  position: relative;
  border-radius: 4px;
  padding: var(--padding-24);
  background-color: var(--up-black-0);
  display: flex;
  justify-content: space-between;
  z-index: 20;
}
.OnboardingNextUp .NextUpCard .NextUpCardIcon {
  position: relative;
  border-radius: 12px;
  background-color: var(--up-purple-10);
  padding: 10px;
  height: 55px;
  width: 55px;
  margin-bottom: 8px;
  z-index: 20;
}
.OnboardingNextUp .NextUpCard .NextUpCardTitle {
  line-height: 24px;
  margin-bottom: 8px;
}
.OnboardingNextUp .NextUpCard .NextUpCardSubTitle {
  line-height: 22px;
  color: var(--up-black-60);
}