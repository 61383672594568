.Button {
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 2px;
  border: none;
}
.Button:disabled {
  cursor: not-allowed;
}
.Button .IconLeft {
  margin-inline-end: 8px;
}
.Button .IconRight {
  margin-inline-start: 8px;
}

.primary {
  background-color: var(--up-blue-40);
  color: var(--up-black-0);
}
.primary:hover {
  background-color: var(--up-blue-50);
}
.primary:disabled {
  background-color: var(--up-black-20);
}

.secondary {
  border: 1px solid var(--up-black-10);
  background-color: var(--up-white);
}
.secondary:hover {
  border-color: var(--up-blue-40);
  color: var(--up-blue-40);
}
.secondary:disabled {
  background-color: var(--up-black-20);
  color: var(--up-black-5);
  border-color: transparent;
}

.destructive {
  border: 1px solid var(--up-red-50);
  color: var(--up-red-50);
  background-color: var(--up-black-0);
}
.destructive:hover {
  background-color: var(--up-red-50);
  color: var(--up-black-0);
}
.destructive:disabled {
  background-color: var(--up-red-10);
  color: var(--up-black-0);
  border-color: var(--up-red-10);
}

.link {
  background-color: transparent;
  color: var(--up-blue-40);
  padding: 0 !important;
  position: relative;
  line-height: 24px;
}
.link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--up-blue-40);
  visibility: hidden;
  transition: all 0.2s ease-in-out;
}
.link:hover::after {
  visibility: visible;
  width: 100%;
}
.link:disabled {
  color: var(--up-black-20);
}
.link:disabled::after {
  visibility: hidden;
}

.ghost {
  background-color: transparent;
}
.ghost:hover {
  background-color: var(--up-black-10);
}
.ghost:disabled {
  color: var(--up-black-20);
}

.xl {
  padding: 12px 24px;
  line-height: 24px;
  font-weight: 600;
  font-size: 16px;
}

.lg {
  padding: 8px 16px;
  line-height: 24px;
  font-weight: 600;
  font-size: 16px;
}

.md {
  padding: 6px 12px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
}