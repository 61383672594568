.StatusCard {
  padding: var(--padding-24);
  border: 1px solid var(--up-black-10);
  border-radius: 4px;
  display: flex;
  gap: 12px;
  cursor: pointer;
}
.StatusCard .StatusCardLeft .StatusCardStateIcon {
  margin-top: 2px;
}
.StatusCard .StatusCardCenter {
  flex-grow: 1;
}
.StatusCard .StatusCardCenter .StatusCardSubTitle {
  margin-top: 8px;
  color: var(--up-black-60);
}
.StatusCard .StatusCardRight {
  display: flex;
  gap: 16px;
}
.StatusCard .StatusCardRight .StatusCardRightArrow {
  width: 18px;
  height: 14px;
  margin-top: 4px;
}