.accordionItem {
  overflow: hidden;
}

.accordionTrigger {
  all: unset;
  cursor: pointer;
  width: 100%;
}

.accordionContent[data-state=open] {
  animation: slideDown 150ms ease-in-out;
}

.accordionContent[data-state=closed] {
  animation: slideUp 150ms ease-in-out;
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}
@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}