.chartTooltip {
  padding: 10px;
  border: 1px solid var(--up-black-10);
  border-radius: 4px;
  box-shadow: var(--box-shadow-main-content);
  background: var(--up-black-0);
  width: max-content;
}
.chartTooltip .current {
  display: flex;
  align-items: stretch;
  gap: 10px;
}
.chartTooltip .compare {
  display: flex;
  align-items: stretch;
  gap: 10px;
  margin-top: 15px;
}
.chartTooltip .highlight {
  width: 3px;
}
.chartTooltip .info {
  padding-right: 15px;
}
.chartTooltip .info .index {
  font-size: 14px;
  color: var(--up-black-50);
  margin-bottom: 8px;
  text-transform: capitalize;
  unicode-bidi: plaintext;
  text-align: left;
}
.chartTooltip .info .index bdi {
  padding-right: 2px;
}
.chartTooltip .info .label {
  font-size: 14px;
  color: var(--up-black-60);
  margin-bottom: 4px;
  text-transform: capitalize;
}
.chartTooltip .info .value {
  font-size: 18px;
  color: var(--up-black-60);
  font-weight: 600;
}
.chartTooltip .info .value.right {
  text-align: right;
}
.chartTooltip .info .compareIndex {
  font-size: 14px;
  color: var(--up-black-50);
  margin-top: 1px;
  margin-bottom: 6px;
  text-transform: capitalize;
  unicode-bidi: plaintext;
  text-align: left;
}
.chartTooltip .info .compareIndex bdi {
  padding-right: 2px;
}
.chartTooltip .info .compareLabel {
  font-size: 14px;
  color: var(--up-black-60);
  margin-bottom: 4px;
  text-transform: capitalize;
}
.chartTooltip .info .compareValue {
  font-size: 14px;
  color: var(--up-black-60);
  font-weight: 600;
}
.chartTooltip .info .infoTip {
  font-size: 14px;
  color: var(--up-black-50);
  margin-top: 8px;
  font-weight: 600;
}